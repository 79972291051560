
import { defineComponent, ref, watch, onMounted, Ref, DefineComponent } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleBrPhoneNumber, campHandleCPF, campHandleEmailVerification, campHandleEmptyFields } from '@/composables/DataValidation';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import CampModalCropImage, { ImgResultsType } from "@/components/CampModalCropImage/CampModalCropImage.vue";
import { CampCropImageLoader, CampCropImageRack, CampUploadImgURL } from '@/components/CampModalCropImage';
import { useAuthStore } from '@/store/AuthStore';
import { ICompanyResponse } from '../Companies/ListPage.vue';
import { SALESMAN_IDS } from './PutPage.vue';

interface IUserRoles {
  id: number,
  level: number,
  name: string,
  description: string
}

interface IUserData {
  cpf: string,
  name: string,
  email: string,
  tel: string,
  id_user_roles: number | null,
  id_store: number | null,
  url_media?: any,
  code_regional_internal_salesman?: string,
}

interface IUserResponse {
  data: {
    error: boolean,
    message: string,
    data: IUserData
    errorCode: string
  },
  status: number
}

interface IUserRolesResponse {
  data: {
    error: boolean,
    message: string,
    data: IUserRoles[]
    errorCode: string
  },
  status: number
}

interface ICampModalCropImage {}

export default defineComponent({
  name: "UserPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    CampCropImageLoader,
    CampCropImageRack,
    CampModalCropImage: CampModalCropImage as unknown as DefineComponent<{}, {}, ICampModalCropImage>
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore();
    const userImgURL = ref(CampUploadImgURL)
    const userImgIsLoading = ref(true)
    const userImgFile = ref<File | null>(null)
    const croppedImgReady: Ref<string | null> = ref(null)
    const userRolesModel = ref<null | IUserRoles[]>(null)
    const initData: IUserData = {
      cpf: '',
      name: '',
      email: '',
      tel: '',
      id_user_roles: null,
      id_store: null
    }
    const storesOptions = ref();
    const userModel = ref<null | IUserData>(initData)
    const isSalesman = ref(false)
    const isRequiredField = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{
      cpf: boolean,
      mail: boolean,
      phoneNumber: boolean
    }>({
      cpf: false,
      mail: false,
      phoneNumber: false
    })
    const authStore = useAuthStore()
    const verifyUserExist = ref({
      isLoading: false,
      exist: false,
      start: false,
      user: {
        role:{
          level: 300
        }
      } as any
    })


    /** Handle User Image */
    function handleImgFileChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        userImgFile.value = file
      }
      fileInput.value = "";
    }

    function handleFileRemoval() {
      userImgIsLoading.value = false
      userImgURL.value = CampUploadImgURL
    }

    function handleCroppedImgBase64(value: ImgResultsType) {
      userImgURL.value = value.croppedImgBase64
      croppedImgReady.value = value.croppedImgBase64
      // console.log("Cropped Img File: ", (value.croppedImgFile.size / 1024**2).toFixed(1) + "MB")
      // console.log("Thumbnail Img File: ", (value.thumbnailImgFile!.size / 1024**2).toFixed(1) + "MB")
    }

    /** Validate CPF */
    watch(() => userModel.value?.cpf, () => {
      if(userModel.value?.cpf) {
        isInvalidField.value.cpf = !campHandleCPF(userModel.value?.cpf)
      }
    })

    /** Validate email */
    watch(() => userModel.value?.email, () => {
      if(userModel.value?.email) {
        isInvalidField.value.mail = !campHandleEmailVerification(userModel.value?.email)
      }
    })

    /** Validate phone number */
    watch(() => userModel.value?.tel, () => {
      if(userModel.value?.tel) {
        isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(userModel.value?.tel)
      }
    })

    async function getDatas() {
      try {
        loaderStore.open()
        type TResponseList = [ ICompanyResponse, IUserRolesResponse ]
        const [ storesResponse, usersRolesResponse ]: TResponseList = await Promise.all([
          axios.get('/api/getStore'),
          axios.get(`/api/getUserRoles`),
        ]);
        storesOptions.value = storesResponse.data.data
        userRolesModel.value = usersRolesResponse.data.data.filter(el => el.level < 600)
      } catch(error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        loaderStore.close()
      }
    }

    /** Reset warning */
    watch(() => userModel.value, () => {
      isRequiredField.value = false
      isInvalidFieldWarning.value = false
    }, { deep: true })

    async function onSubmitForm() {
      if(authStore.user.company === null && authStore.getUserLevel <= 100) {
        return showTimeAlert("Necessario estar em uma companhia para adicionar um usuário", "error")
      }
      if(!userModel.value) return

      const { cpf, tel, ...res } = userModel.value

      const postCpf = campHandleCPF(cpf, "unformatted").toString()
      if(!campHandleCPF(postCpf))
        return !userModel.value.cpf.length ? isRequiredField.value = true : (isRequiredField.value = true, isInvalidFieldWarning.value = true)

      const postTel = campHandleBrPhoneNumber(tel, "unformatted").toString()
      if(!campHandleBrPhoneNumber(postTel))
        return !userModel.value.tel.length ? isRequiredField.value = true : (isRequiredField.value = true, isInvalidFieldWarning.value = true)

      if(isInvalidField.value.mail)
        return !userModel.value.email.length ? isRequiredField.value = true : (isRequiredField.value = true, isInvalidFieldWarning.value = true)

      const postUser: IUserData & { [key: string]: string | number | null } = {
        ...res,
        cpf: postCpf,
        tel: postTel
      }
      const {id_store, url_media, code_regional_internal_salesman, ...newPostUser} = postUser
      if(!verifyUserExist.value.exist) {
        if(campHandleEmptyFields(newPostUser))
          return isRequiredField.value = true
      }
      newPostUser.id_store = id_store

      try {
        loaderStore.open()
        const response: IUserResponse = await axios.post(`/api/postUser`, {
          ...postUser,
          base64imgData: croppedImgReady.value
        })
        if(!response.data.error) {
          userModel.value = { ...initData }
          showTimeAlert("Registro criado com sucesso!")
        } else {
          showTimeAlert("Ops! Algo deu errado! :(", "error")
        }
        return router.push("/usuarios")
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      } 
    }

    async function onBlur(e) {
      const cpf = e.target.value
      if(!campHandleCPF(cpf)) {
        verifyUserExist.value.start = false
      }
      if(cpf.length === 14 && campHandleCPF(cpf)) {
        verifyUserExist.value.start = true
        verifyUserExist.value.isLoading = true
        try {
      const response = await axios.get(`/api/getVerifyExistUserCpf/${cpf}`)
          if(response.data.data.user) {
            userModel.value = response.data.data.user
            userImgURL.value = response.data.data.user.url_media
            verifyUserExist.value.user = response.data.data.user
          }

          if(response.data.data.userExist === false) {
            userModel.value = {
              cpf: cpf,
              name: '',
              email: '',
              tel: '',
              url_media: '',
              id_user_roles: null,
              id_store: null
            }
            userImgURL.value = CampUploadImgURL
            verifyUserExist.value.user.role.level = 300
            return verifyUserExist.value.exist = false
          }
          verifyUserExist.value.exist = true
        } catch (error) {
          
        } finally {
          verifyUserExist.value.isLoading = false
        }
      }
    }

    const handleSalesmanLevel = () => {
      if(!(userModel.value?.id_user_roles))
        return
      isSalesman.value = SALESMAN_IDS.includes(userModel.value.id_user_roles)
    }

    watch(() => userModel.value?.id_user_roles, handleSalesmanLevel)

    onMounted(async () => (getDatas(), handleSalesmanLevel()))

    return {
      userImgURL,
      userImgFile,
      userImgIsLoading,
      CampUploadImgURL,
      handleImgFileChange,
      handleFileRemoval,
      handleCroppedImgBase64,
      userRolesModel,
      userModel,
      isRequiredField,
      isInvalidFieldWarning,
      isInvalidField,
      onSubmitForm,
      storesOptions,
      onBlur,
      verifyUserExist,
      isSalesman,
    }
  }
})
